<script lang="ts">
	import { classes } from '@thearc-hq/ui-kit/utils';
	import Button from '$lib/components/Button/Button.svelte';

	export let disabled: boolean | undefined = undefined;
	let className: string | undefined = '';

	export { className as class };
</script>

<Button
	on:click
	{...$$restProps}
	class={classes(
		'text-accent-primary border border-transparent bg-transparent uppercase',
		!disabled &&
			'hover:text-dark-secondary hover:border-dark-secondary focus:text-dark-secondary focus:border-dark-secondary focus:!outline-dark-quaternary active:text-dark-secondary active:border-dark-primary active:outline-none',
		className
	)}
	{disabled}
>
	<slot name="icon" slot="icon" />
</Button>
